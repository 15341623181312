<template>
  <list :reference="reference" :sorters="sorters" :active-sort="activeSort">
    <template slot-scope="order">
      <list-card
        :title="`Order #${order.item.orderNum}`"
        :subtitle-dark="
          `Total ${$formatCurrency(order.item.total, order.item.currency)}`
        "
        :label="order.item.status"
        :timestamp="order.item.dateCreated.toDate()"
        :route="{ path: `/admin/orders/${order.item._id}` }"
      />
    </template>
  </list>
</template>

<script>
import { collection } from "@firebase/firestore";
export default {
  name: "AdminOrders",
  data() {
    return {
      reference: collection(this.$firestore, "orders"),
      sorters: [
        {
          field: "orderNum",
          label: "Order Number"
        },
        {
          field: "total",
          label: "Total"
        },
        {
          field: "dateCreated",
          label: "Date Created"
        }
      ],
      activeSort: {
        field: "dateCreated",
        direction: "desc"
      }
    };
  }
};
</script>
